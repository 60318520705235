module.exports = {
  highlight: {
    DEFAULT: "#FF4619",
    hover: "#FF704D",
    ghost: {
      50: "rgba(255, 112, 77, 0.05)",
      150: "rgba(255, 112, 77, 0.15)",
    },
  },
  primary: {
    DEFAULT: "#005795",
    hover: "#606060",
    ghost: {
      50: "rgba(255, 255, 255, 0.05)",
      150: "rgba(255, 255, 255, 0.2)",
    },
  },
  secondary: {
    DEFAULT: "#113862",
    hover: "#606060",
    ghost: {
      50: "rgba(255, 255, 255, 0.05)",
      150: "rgba(255, 255, 255, 0.2)",
    },
  },
  tertiary: "#5EADF2",

  success: "#89d27d",
  error: "#D00F26",
  focus: "#6c9cde",
  white: "#fff",
  background: "#FBFBFB",
  menu: "#005795",

  gray: {
    g4: "#262626",
    g3: "#767171",
    g2: "#AFABAB",
    g1: "#D9D9D9",
    g0: "#fff",
  },
}
