import React from "react"
import PropTypes from "prop-types"

function Logo({ theme, forceLogoColor, isOpen, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="206" height="46" fill="none" viewBox="0 0 206 46">
      <g clipPath="url(#clip0_300_21965)">
        <path
          fill={forceLogoColor ? forceLogoColor : theme === "light" || isOpen ? "#FF4619" : "#262626"}
          d="M0 34.697h10.15V20.184c0-2.817.821-4.798 3.431-4.798 3.085 0 3.337 2.272 3.337 4.798v14.513h10.114V20.184c0-2.817.82-4.798 3.44-4.798 3.066 0 3.327 2.272 3.327 4.798v14.513h10.15V16.316c0-4.337-2.31-9.087-9.125-9.087-6.608 0-8.66 3.924-9.07 4.177-1.23-2.685-4.306-4.177-7.186-4.177-3.682 0-6.655 1.135-8.715 4.233h-.094V7.895H0v26.802z"
        ></path>
        <path
          fill={forceLogoColor ? forceLogoColor : theme === "light" || isOpen ? "#FBFBFB" : "#262626"}
          d="M78.429 8.055l-.354 2.581a10.898 10.898 0 00-3.682-2.617 10.83 10.83 0 00-4.418-.894c-6.255 0-11.186 4.647-11.186 13.528 0 8.45 4.511 13.631 11.381 13.631a9.68 9.68 0 004.11-.745 9.741 9.741 0 003.422-2.41v3.512c0 2.638-1.175 3.98-4.353 3.98-1.864 0-3.281-.47-4.055-2.272l-9.843.874c1.389 5.839 6.003 8.627 13.432 8.627 9.74 0 15.175-4.807 15.175-12.908V8.055h-9.629zm-5.07 18.175c-2.36 0-4-1.653-4-5.736 0-4.084 1.64-5.783 4-5.783 2.796 0 4.455 2.375 4.455 5.783 0 3.407-1.687 5.736-4.456 5.736zm37.564-18.175V22.24c0 2.816-1.641 4.75-3.999 4.75-2.051 0-3.384-1.342-3.384-3.924V8.046H93.212v17.498c0 6.459 3.729 9.914 10.002 9.914a9.427 9.427 0 004.657-.989 9.505 9.505 0 003.593-3.142l.363 3.36h9.48V8.056h-10.384zm43.651 14.4c0-7.585-4.455-15.33-14.662-15.33-8.762 0-14.765 5.633-14.765 14.298s5.593 14.307 14.868 14.307c7.177 0 12.052-3.36 13.981-8.88l-9.684-.62a4.318 4.318 0 01-1.741 1.763 4.27 4.27 0 01-2.417.508c-2.712 0-4.353-1.398-4.865-4.543h19.323v-1.502h-.038zm-14.755-8.101c2.414 0 3.896 1.183 4.306 3.971h-8.762c.56-2.816 2.051-3.971 4.456-3.971zm30.9 21.376c8.389 0 13.842-3.098 13.842-9.5 0-4.497-3.225-7.595-10.664-8.365l-3.943-.413c-2.153-.207-3.029-1.089-3.029-2.328 0-1.446 1.286-2.216 3.384-2.216 2.461 0 3.728.873 3.999 2.629h9.433c-.867-5.473-5.276-8.45-13.05-8.45-8.156 0-13.05 3.146-13.05 8.929 0 4.694 3.328 7.388 10.356 8.007l3.999.357c2.051.207 2.917 1.033 2.917 2.375 0 1.605-1.333 2.685-3.793 2.685-2.61 0-3.999-1.033-4.307-3.145h-9.741c.672 5.727 5.23 9.388 13.638 9.388l.009.047zm30.285-21.592l4.865.103V8.045l-4.865.104V-.01h-10.356v8.167l-4.055-.103v6.196l4.055-.103v11.265c0 7.022 2.255 9.294 9.228 9.294h5.993v-7.773h-2.302c-1.697 0-2.563-.94-2.563-2.892v-9.904z"
        ></path>
        <path
          fill={forceLogoColor ? forceLogoColor : theme === "light" || isOpen ? "#FF4619" : "#262626"}
          d="M51.845 34.894a4.542 4.542 0 003.223-1.344 4.608 4.608 0 001.335-3.246c0-1.218-.48-2.386-1.335-3.246a4.541 4.541 0 00-3.223-1.345 4.542 4.542 0 00-3.223 1.345 4.607 4.607 0 00-1.335 3.245c0 1.218.48 2.386 1.335 3.247a4.542 4.542 0 003.223 1.344z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_300_21965">
          <path fill="#fff" d="M0 0H206V46H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

Logo.propTypes = {
  color: PropTypes.string,
  forceLogoColor: PropTypes.string,
  isOpen: PropTypes.bool,
  theme: PropTypes.string,
}

Logo.defaultProps = {
  color: "#FF4619",
}

export default Logo
