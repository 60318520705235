import React from "react";
import Container from "../../components/containers/Container";
import SpaceshipIcon from "../../components/icons/SpaceshipIcon";
import { RichTextMediaWrapper } from "../RichTextMedia/RichTextMedia";

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

export default function PricingTable({ content }) {
  const { identifier, pricingTable, headline } = content;

  const checkFeature = (featureText) => {
    if (featureText?.toLowerCase() === "yes")
      return <span className="text-primary text-base">✓</span>;
    if (featureText?.toLowerCase() === "no")
      return <span className="text-primary text-h6">𐄂</span>;

    return featureText;
  };

  return (
    <Container
      id={identifier}
      type="wide"
      className="px-0"
      // className="pb-16 pt-6 lg:pb-28 lg:pt-10"
      // className="px-0 md:px-8 lg:px-12"
    >
      <div>
        <div className="px-6 md:px-0">
          <RichTextMediaWrapper
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        </div>
        {/* Desktop Table */}
        <div className="mt-8 flex-col hidden md:flex relative overflow-hidden mt-36">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0 bg-primary"
                    ></th>
                    <th
                      scope="col"
                      className="w-1/5 px-3 text-left text-sm font-semibold text-gray-900 text-center pt-12
                      "
                    >
                      <div
                        className={
                          "flex justify-center items-center place-self-center w-8 h-8 md:w-16 md:h-16 xl:w-28 xl:h-28 rounded-full text-white group-hover:bg-white bg-primary mx-14 2.5xl:mx-24 "
                        }
                      >
                        <SpaceshipIcon width={45} height={45} />
                      </div>
                      <h4 className="text-primary font-serif italic mt-6 text-h7 xl:text-h4">
                        Basic
                      </h4>
                    </th>
                    <th
                      scope="col"
                      className="w-1/5 px-3 text-left text-sm font-semibold text-gray-900 text-center pt-12
                      "
                    >
                      <div
                        className={
                          "flex justify-center items-center w-8 h-8 md:w-16 md:h-16 xl:w-28 xl:h-28 rounded-full text-white group-hover:bg-white bg-primary mx-14 2.5xl:mx-24 "
                        }
                      >
                        <SpaceshipIcon width={45} height={45} />
                      </div>
                      <h4 className="text-primary font-serif italic mt-6 text-h7 xl:text-h4">
                        Pro
                      </h4>
                    </th>
                    <th
                      scope="col"
                      className="w-1/5 px-3 text-left text-sm font-semibold text-gray-900 text-center pt-12
                      "
                    >
                      <div
                        className={
                          "flex justify-center items-center w-8 h-8 md:w-16 md:h-16 xl:w-28 xl:h-28 rounded-full text-white group-hover:bg-white bg-primary mx-14 2.5xl:mx-24 "
                        }
                      >
                        <SpaceshipIcon width={45} height={45} />
                      </div>
                      <h4 className="text-primary font-serif italic mt-6 text-h7 xl:text-h4">
                        Enterprise
                      </h4>
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {pricingTable.map((feature, i) => {
                    const last = pricingTable.length === i + 1;

                    return (
                      <tr
                        key={`${feature.email}-${i}`}
                        className={
                          "text-gray-g3 " +
                          (feature["pro"] ||
                          feature["basic"] ||
                          feature["enterprise"]
                            ? ""
                            : "align-bottom font-bold")
                        }
                      >
                        <td
                          className={
                            "whitespace-pre-wrap px-10 text-sm text-white bg-primary h-24 relative "
                          }
                        >
                          <div
                            className={
                              "h-full flex py-8 " +
                              ((feature["pro"] ||
                                feature["basic"] ||
                                feature["enterprise"]) &&
                              !last
                                ? "border-b border-white/70 "
                                : " ") +
                              (feature["pro"] ||
                              feature["basic"] ||
                              feature["enterprise"]
                                ? "items-center text-h7 "
                                : "items-end text-h6 ")
                            }
                          >
                            {feature.featureName}
                          </div>
                        </td>
                        <td className="whitespace-pre-wrap text-base text-gray-500 text-center h-24 relative">
                          <div
                            className={
                              "h-full flex justify-center py-3 " +
                              ((feature["pro"] ||
                                feature["basic"] ||
                                feature["enterprise"]) &&
                              !last
                                ? "border-b border-gray-g1/70 "
                                : " ") +
                              (feature["pro"] ||
                              feature["basic"] ||
                              feature["enterprise"]
                                ? "items-center "
                                : "items-end ")
                            }
                          >
                            <p className="px-6">
                              {checkFeature(feature["basic"])}
                            </p>
                          </div>
                        </td>
                        <td className="whitespace-pre-wrap text-base text-gray-500 text-center h-24 relative">
                          <div
                            className={
                              "h-full flex justify-center py-3 " +
                              ((feature["pro"] ||
                                feature["basic"] ||
                                feature["enterprise"]) &&
                              !last
                                ? "border-b border-gray-g1/70 "
                                : " ") +
                              (feature["pro"] ||
                              feature["basic"] ||
                              feature["enterprise"]
                                ? "items-center "
                                : "items-end ")
                            }
                          >
                            <p className="px-6">
                              {checkFeature(feature["pro"])}
                            </p>
                          </div>
                        </td>
                        <td className="whitespace-pre-wrap text-base text-gray-500 text-center h-24 relative">
                          <div
                            className={
                              "h-full flex justify-center py-3 " +
                              ((feature["pro"] ||
                                feature["basic"] ||
                                feature["enterprise"]) &&
                              !last
                                ? "border-b border-gray-g1/70 "
                                : " ") +
                              (feature["pro"] ||
                              feature["basic"] ||
                              feature["enterprise"]
                                ? "items-center "
                                : "items-end ")
                            }
                          >
                            <p className="px-6">
                              {checkFeature(feature["enterprise"])}
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* Mobile Table */}
        <div className="flex flex-col md:hidden relative overflow-hidden">
          {pricingTable.map((feature, i) => {
            if (!feature["basic"] && !feature["pro"] && !feature["enterprise"])
              return (
                <h6 className="mt-24 mb-8 ml-6 text-gray-g3" key={i}>
                  {feature.featureName}
                </h6>
              );
            return (
              <div className="w-full flex flex-col" key={i}>
                <div className="bg-primary w-full text-white px-6 py-3 text-[20px]">
                  {feature.featureName}
                </div>
                <div className="w-fit flex overflow-x-scroll relative">
                  <div className="flex">
                    <div className="text-gray-g3 my-4 px-6 w-36">
                      <p className="font-bold">Basic</p>
                      <p className="whitespace-pre-wrap break-words">
                        {checkFeature(feature["basic"])}
                      </p>
                    </div>
                    <div className="text-gray-g3 my-4 px-6 w-36 border-r border-l border-gray-g1/70">
                      <p className="font-bold">Pro</p>
                      <p className="whitespace-pre-wrap break-words">
                        {checkFeature(feature["pro"])}
                      </p>
                    </div>
                    <div className="text-gray-g3 my-4 px-6 w-36">
                      <p className="font-bold">Enterprise</p>
                      <p className="whitespace-pre-wrap break-words">
                        {checkFeature(feature["enterprise"])}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
}
